.questionsSection {
  padding: 76px 0;
}
.title {
  font-size: 40px;
  font-weight: 700;
}
.title > span {
  font-weight: 300;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 38px;
  width: 70%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url('../../assets/imgs/questions-img.png');
  background-size: cover;
  border-radius: 15px;
  padding: 50px;
}
.leftText {
  font-size: 17px;
  font-weight: 400;
  color: white;
  width: 60%;
}
.left > div {
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.input {
  padding: 20px;
  background-color: white;
  color: rgba(130, 130, 130, 1);
  border: none;
  border-radius: 5px;
  outline: none;
}
.button {
  background-color: rgba(177, 17, 22, 1);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 20px;
}
.agreement {
  font-size: 10px;
  font-weight: 300;
  color: white;
  text-align: center;
}
.agreement > a {
  text-decoration: underline;
  color: white;
}
.contactsWrapper {
  display: flex;
  gap: 34px;
}
.right {
  background-color: black;
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 40px;
  width: 30%;
  border-radius: 15px;
}
.rightTitle {
  color: white;
  font-size: 24px;
  font-weight: 700;
}
.rightText {
  color: rgba(130, 130, 130, 1);
  font-size: 17px;
  font-weight: 400;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.contactsIcons a {
  padding: 13px;
  align-items: center;
  display: flex;
  border: 1px solid rgba(68, 68, 68, 0.5);
  background: linear-gradient(0deg, rgba(33, 33, 32, 0.2), rgba(33, 33, 32, 0.2)),
    linear-gradient(0deg, rgba(68, 68, 68, 0.5), rgba(68, 68, 68, 0.5));
}
.number {
  color: white !important;
}
.contactsIcons img {
  width: 25px;
}
.contactsIcons {
  display: flex;
  gap: 15px;
}
@media screen and (max-width: 992px) {
  .title {
    font-size: 30px;
  }
  .left > div {
    width: 70%;
  }
  .left {
    justify-content: center;
    padding: 30px;
  }
  .right {
    padding: 30px;
  }
  .leftText {
    width: 80%;
  }
  .contactsIcons img {
    width: 15px;
  }
}
@media screen and (max-width: 786px) {
  .contactsWrapper {
    flex-direction: column;
  }
  .left > div {
    width: 100%;
  }
  .leftText {
    width: 100%;
  }
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 24px;
  }
}
