.footer {
  padding: 66px 0;
  background: linear-gradient(176.19deg, rgba(0, 0, 0, 0.9) 2.72%, rgba(0, 0, 0, 0.7) 96.57%);
}
.contactsIcons a {
  padding: 10px;
  align-items: center;
  display: flex;
  border: 1px solid rgba(68, 68, 68, 0.5);
  background: linear-gradient(0deg, rgba(33, 33, 32, 0.2), rgba(33, 33, 32, 0.2)),
    linear-gradient(0deg, rgba(68, 68, 68, 0.5), rgba(68, 68, 68, 0.5));
}
.contactsWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.logoImage {
  height: 90px;
}
.contactsWrapper > a {
  color: white;
}
.itemsWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.itemsWrapper > a {
  font-size: 21px;
  font-weight: 400;
  color: rgba(161, 161, 161, 1);
}
.itemsContainer {
  display: flex;
  gap: 54px;
}
.contactsIcons {
  display: flex;
  gap: 15px;
}
.wrapper {
  display: flex;
  gap: 54px;
}
.contactsIcons > a > img {
  width: 40px;
}
.phoneContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
@media screen and (max-width: 1200px)  {
  .itemsWrapper  > a{
    font-size: 18px;
  }
}
@media screen and (max-width: 992px) {
  .logoImage {
    height: 70px;
  }
  .logoImage {
    width: 204px;
  }
  .wrapper {
    flex-direction: column;
  }
  .itemsWrapper > a {
    font-size: 19px;
  }
  

}
@media screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column;
  }
  .logoImage {
    width: 204px;
  }
  .contactsWrapper {
    display: flex;
    align-self: center;
  }
  .itemsWrapper {
    gap: 18px;

  }
   .itemsWrapper > a {
    font-size: 15px;
  }
  .contactsWrapper {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 365px) { 
  .itemsWrapper > a {
    font-size: 13px;
  }

}