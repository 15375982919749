.cardWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
  width: 270px;
  height: 256px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.cardTitle {
  font-size: 21px;
  font-weight: 700;
}
.bottom {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.cardText {
  font-size: 14px;
  font-weight: 400;
  color: rgba(130, 130, 130, 1);
}
.cardImage {
  width: 55px;
  height: 55px;
}

@media screen and (max-width: 1400px) {
  .cardWrapper {
    padding: 20px;
    width: 230px;
    height: 230px;
    gap: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .cardWrapper {
    padding: 25px;
    width: 270px;
    height: 256px;
    gap: 20px;
  }
}
@media screen and (max-width: 576px) {
  .cardWrapper {
    width: 100%;
    box-shadow: none;
    background-color: white;
    height: 132px;

    flex-direction: row;
    padding: 24px 20px;
  }
  .bottom {
    gap: 6px;
  }
  .cardTitle {
    font-size: 17px;
  }
  .cardText {
    font-size: 12px;
  }
}

@media screen and (max-width: 360px) {
  .cardWrapper {
    height: 142px;
  }
}
