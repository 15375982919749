.bestOffersSection {
  padding: 76px 0;
}
.cardButton {
  color: rgba(177, 17, 22, 1) !important;
  padding: 8px 0;
  border: 2px solid rgba(177, 17, 22, 1);
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
}
.cardButton > img {
  color: rgba(177, 17, 22, 1) !important;
}
.cardsContainer > div {
  display: flex;
  gap: 60px;
  justify-content: center;
}
.cardWrapper {
  display: flex;
  gap: 40px;
}

.cardDescRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 170px;
}
.cardsContainer {
  display: flex;
  gap: 57px;
}

.title {
  font-size: 40px;
  font-weight: 700;
}
.title > span {
  font-weight: 300;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 45px;
}
.cardModel {
  font-size: 24px;
  font-weight: 700;
}

.cardInfo {
  display: flex;
  justify-content: space-between;
}
.cardPrice {
  font-size: 21px;
  font-weight: 400;
}
.cardInfoLeft {
  font-size: 14px;
  color: rgba(130, 130, 130, 1);
}
.cardInfoRight {
  font-size: 14px;
  color: rgba(68, 68, 68, 1);
  font-weight: 400;
}
.cardDescription {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.cardLine {
  border: 1.5px solid rgba(217, 217, 217, 1);
  width: 70%;
}
.right {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1200px) {
  .cardInfo {
    flex-direction: column;
  }
  .cardWrapper > img {
    width: 55%;
  }
  .cardButton {
    margin-top: 20px;
  }
  .cardWrapper {
    width: 90%;
    gap: 20px;
  }
  .cardsContainer {
    gap: 30px;
  }
  .cardsContainer > div {
    gap: 30px;
  }
}
@media screen and (max-width: 992px) {
  .cardsContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .cardButton {
    padding: 8px 30px;
  }
  .title {
    font-size: 30px;
  }
  .cardDescRight {
    width: auto;
  }
}
@media screen and (max-width: 768px) {
  .cardWrapper {
    flex-direction: column;
    width: 100%;
  }
  .cardWrapper > img {
    width: 100%;
  }
  .cardPrice {
    display: none;
  }
  
}
@media screen and (max-width: 576px) {
  .title {
    font-size: 24px;
  }
}
