.wrapper {
  padding: 31px;
  margin: 0 0 80px;
  background-color: rgba(16, 16, 16, 1);
  overflow: hidden;
}
.itemsWrapper {
  gap: 73px;
  display: flex;
  animation: ticker 20s infinite linear;
}
.itemsWrapper > p {
  font-size: 16px;
  font-weight: 700;
  color: rgba(83, 83, 83, 1);
}
@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes tickerMobile {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-200%, 0, 0);
    transform: translate3d(-200%, 0, 0);
  }
}
@media screen and (max-width: 992px) {
  .itemsWrapper {
    animation-duration: 10s;
  }
}
@media screen and (max-width: 786px) {
  .itemsWrapper {
    animation-name: tickerMobile;
  }
}
