.popularModelsSection {
  padding: 76px 0;
}
.cardButton {
  color: rgba(177, 17, 22, 1) !important;
  padding: 8px 80px;
  border: 2px solid rgba(177, 17, 22, 1);
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
}
.cardButton > img {
  color: rgba(177, 17, 22, 1) !important;
}
.cardsContainer {
  display: flex;
  gap: 28px;
  justify-content: center;
}
.cardWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 25%;
}
.cardModel {
  font-size: 24px;
  font-weight: 700;
}
.title {
  font-size: 40px;
  font-weight: 700;
}
.title > span {
  font-weight: 300;
}
.cardInfo {
  display: flex;
  justify-content: space-between;
}
.cardPrice {
  font-size: 21px;
  font-weight: 900;
}
.cardInfoLeft {
  font-size: 14px;
  color: rgba(130, 130, 130, 1);
}
.cardInfoRight {
  font-size: 14px;
  color: rgba(68, 68, 68, 1);
  font-weight: 700;
}
.cardDescription {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.cardLine {
  border: 1.5px solid rgba(217, 217, 217, 1);
  width: 70%;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 45px;
}

@media screen and (max-width: 1200px) {
  .cardWrapper {
    width: 45%;
  }
  .cardsContainer {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 992px) {
  .title {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .cardWrapper {
    width: 70%;
  }
  .cardsContainer {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 24px;
  }
  .cardModel {
    font-size: 20px;
  }
}
