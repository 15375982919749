.howWeWorkSection {
  padding: 76px 0;
}
.newItem2 {
  display: none !important;
}
.newItem {
  display: none !important;
}
.circle {
  position: absolute;
  width: 484px;
  height: 484px;
  border-radius: 50%;
  background: rgba(217, 217, 217, 1);
  filter: blur(100px);
  z-index: -10;
  top: -80px;
  right: 0px;
}
.cardsContainer > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerMobile {
  display: none !important;
}
.cardWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 57px;
}
.grayLine {
  border: 3px solid rgba(228, 228, 228, 1);
  width: 66px;
  height: 5px;
}
.title {
  font-size: 40px;
  font-weight: 700;
}
.title > span {
  font-weight: 300;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 45px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .lastItem {
    display: none !important;
  }
  .newItem {
    display: flex !important;
  }
}
@media screen and (max-width: 992px) {
  .title {
    font-size: 30px;
  }
  .lastItem2 {
    display: none !important;
  }
  .newItem2 {
    display: flex !important;
  }
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 24px;
  }
  .grayLine {
    display: none !important;
  }
  .containerNone {
    display: none;
  }
  .containerMobile {
    display: flex !important;
  }
  .cardsWrapper {
    gap: 13px;
    flex-direction: column;
  }
}
