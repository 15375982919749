.workBenefitsSection {
  padding: 76px 0;
}

.title {
  font-size: 40px;
  font-weight: 700;
}
.title > span {
  font-weight: 300;
}
.overlay {
  background-color: rgba(247, 247, 247, 0.88);
  padding: 80px;
}
.left {
  width: 65%;
  background-image: url('../../assets/imgs/benefits-bg.png');
  background-size: cover;
}
.right {
  width: 35%;
  background-color: black;
  padding: 147px 41px 0;
  position: relative;
}
.carImage {
  position: absolute;
  bottom: -50px;
  width: 27vw;
  left: -50px;
}
.rightText {
  font-size: 24px;
  font-weight: 700;
  color: white;
}
.leftText {
  color: rgba(83, 83, 83, 1);
  font-size: 21px;
  margin-top: 27px;
}

.wrapper {
  display: flex;
}
.titleWrapper {
  display: flex;
  gap: 35px;
}
.cardsContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 70px;
}
.cardTop {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cardWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30%;
}
.title > span > img {
  display: none;
}
.cardNumber {
  font-size: 35px;
  font-weight: 900;
}
.cardLine {
  border: 1.5px solid rgba(177, 17, 22, 1);
  width: 70%;
}
.cardText {
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 992px) {
  .title {
    font-size: 30px;
  }
  .overlay {
    padding: 60px;
  }
  .cardsContainer {
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 40px;
    justify-content: flex-start;
  }
  .cardWrapper {
    width: 170px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column;
  }
  .carImage {
    width: 250px;
  }
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
    padding: 26px 30% 50px 11px;
  }
  .carImage {
    right: 0;
    left: auto;
  }
  .cardsContainer {
    flex-direction: column;
    gap: 30px;
  }
  .cardWrapper {
    width: 100%;
    flex-direction: row;
  }
  .cardTop {
    gap: 10px;
    align-items: center;
  }
  .cardLine {
    width: 130%;
  }
  .overlay {
    padding: 50px 20px;
  }
  .title > br {
    display: none;
  }
  .title > span > img {
    width: 40px;
    display: inline;
  }
  .title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .title > span {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .titleWrapper > img {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .title {
    font-size: 24px;
  }
  .carImage {
    width: 174px;
  }
}
