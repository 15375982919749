.buyBenefitsSection {
  padding: 76px 0;
  position: relative;
}
.wrapper {
  background-color: rgba(250, 249, 248, 1);
  padding: 53px 80px;
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 38px;
}
.text {
  font-size: 17px;
  font-weight: 400;
  color: rgba(68, 68, 68, 1);
}
.title > span {
  font-weight: 300;
}
.title {
  font-size: 40px;
  font-weight: 700;
}
.imgWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 25px;
  top: 50%;
  right: 150px;
  transform: translateY(-50%);
}
.imgWrapper > img {
  width: 32vw;
}

.imageContainer > img {
  width: 15vw;
}
.imageContainer {
  display: flex;
  gap: 32px;
}
@media screen and (max-width: 992px) {
  .title {
    font-size: 30px;
  }
  .imgWrapper > img {
    width: 38vw;
  }

  .imageContainer > img {
    width: 17.3vw;
  }
  .imgWrapper {
    right: 80px;
  }
  .text {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
  }
  .buyBenefitsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .imageContainer > img {
    width: 100%;
  }
  .imgWrapper > img {
    width: 100%;
  }
  .imageContainer {
    gap: 17px;
  }
  .imgWrapper {
    margin-top: 18px;
    gap: 17px;
    position: relative;
    transform: translateX(0);
    right: 0;
  }
  .imageContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 576px) {
  .title {
    font-size: 24px;
  }
  .wrapper {
    padding: 30px 10px;
  }
}
