body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   overflow-x: hidden;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}
* {
   box-sizing: border-box;

   margin: 0;
}
body {
   overflow-x: hidden !important;
}

.container {
   width: 1440px;
   padding: 0 80px;
   margin: 0 auto;
}

a {
   text-decoration: none;
   color: rgba(161, 161, 161, 1);
}
a:visited {
   color: rgba(161, 161, 161, 1);
}
a:hover {
   cursor: pointer;
}

.swiper-button-disabled {
   border: 1px solid #d9d9d9 !important ;
}
.swiper-button-disabled > svg > path {
   fill: #c1c1c1 !important ;
}
@media screen and (max-width: 1400px) {
   .container {
      width: 1200px;
   }
}
@media screen and (max-width: 1200px) {
   .container {
      width: 960px;
      padding: 0;
   }
}
@media screen and (max-width: 992px) {
   .container {
      width: 720px;
   }
}
@media screen and (max-width: 768px) {
   .container {
      width: 540px;
   }
}
@media screen and (max-width: 576px) {
   .container {
      width: 100%;
      padding: 20px;
   }
}
