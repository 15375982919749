.clearanceSection {
  padding: 76px 0;
}
.title > span {
  font-weight: 300;
}
.title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 50px;
}
.wrapper {
  display: flex;
  align-items: center;
}
.left {
  width: 50%;
  height: 627px;
}
.right {
  display: flex;
  width: 55%;
  margin-left: -30px;
  flex-direction: column;
  gap: 21px;
  border: 1px solid rgba(217, 217, 217, 1);
  padding: 60px;
}
.right > p {
  font-size: 17px;
  font-weight: 400;
}
@media screen and (max-width: 992px) {
  .title {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .right > p {
    font-size: 14px;
  }
  .wrapper {
    flex-direction: column;
  }
  .left {
    width: 100%;
  }
  .right {
    width: 100%;

    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .title {
    font-size: 24px;
  }
  .right {
    padding: 20px;
  }
}
